import { ColorModeContext, useMode } from "./theme";
// import { CssBaseline, ThemeProvider } from "@mui/material/core";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Helmet} from "react-helmet";

// Pages
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";

function App() {
    const [theme, ColorMode] = useMode();

    // To setup our color mode context & have access to it globally
    return (
        <ColorModeContext.Provider value={ColorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <div className="app">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>YodaKode | Sample Admin Dashboard</title>
                            <link
                                rel="canonical"
                                href="https://ecomm-react-dashboard.onrender.com/"
                            />
                        </Helmet>
                        <Sidebar />
                        <main className="content">
                            <Topbar />
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/team" element={<Team />} />
                                <Route
                                    path="/contacts"
                                    element={<Contacts />}
                                />
                                <Route
                                    path="/invoices"
                                    element={<Invoices />}
                                />
                                <Route path="/form" element={<Form />} />
                                <Route
                                    path="/calendar"
                                    element={<Calendar />}
                                />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/bar" element={<Bar />} />
                                <Route path="/pie" element={<Pie />} />
                                <Route path="/line" element={<Line />} />
                                <Route
                                    path="/geography"
                                    element={<Geography />}
                                />
                            </Routes>
                        </main>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
